import * as cryptoJS from 'crypto-js';

export function useStringEncryption () {
  const runtimeConfig = useRuntimeConfig();

  function encryptData (data: string): string {
    if (data.length) {
      // hash the name with any algorithm
      const encData = cryptoJS.AES.encrypt(
        data,
        runtimeConfig.public.encSecret
      ).toString();

      return encData;
    }
    return '';
  }
  function decryptData (data: string): string {
    // decrypt the data and convert to string
    if (data.length) {
      const decryptData = cryptoJS.AES.decrypt(
        data,
        runtimeConfig.public.encSecret
      ).toString(cryptoJS.enc.Utf8);
      return decryptData;
    }
    return '';
  }

  return { encryptData, decryptData };
}

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import { faker } from '@faker-js/faker';
import { useSettingsStore } from '~/stores/shared/settings';
import DescriptionList from '~/components/pro/molecules/Data/DescriptionList.vue';
import ListItem from '~/components/pro/atoms/Data/ListItem.vue';
import LabelWithAuxText from '~/components/pro/atoms/Option/LabelWithAuxText.vue';
import { Breakpoints } from '~/config';
import { useHydraNodeStore } from '~/stores/hydra-node';
import { useMnemonicManager } from '~/composables/shared/useMnemonicManager';
import { useWalletStore } from '~/stores/shared/wallet';
import { useCurrencyStore } from '~/stores/shared/currency';
import ModalHeader from '~/components/pro/templates/Modals/ModalHeader.vue';

defineProps<{
  close:Function
}>();

const { t } = useI18n();
const { width } = useWindowSize();
const hydraStore = useHydraNodeStore();
const settingsStore = useSettingsStore();
const walletStore = useWalletStore();
const currencyStore = useCurrencyStore();
const { preferences, timezones, currencies, resetToDefaults } = settingsStore;
const toast = useToast();

const {
  primaryColors,
  grayTones,
  themeOptions,
  theme,
  primaryColor,
  grayTone
} = storeToRefs(settingsStore);

const { mnemonic } = storeToRefs(hydraStore);

const { resetLocalStorageMnemonic, setLocalStorageMnemonic } =
  useMnemonicManager();

watch(() => preferences.persistMnemonic, () => {
  if (preferences.persistMnemonic) {
    setLocalStorageMnemonic(mnemonic.value?.toString() || '');
  } else {
    resetLocalStorageMnemonic();
  }
});

enum NavigationKeys {
  PREFERENCES = 'preferences',
  SECURITY = 'security',
  CHANGELOG = 'changelog'
}
const navigation = [
  {
    label: t('settings.modal.preferences'),
    icon: 'i-heroicons-cog-8-tooth',
    key: NavigationKeys.PREFERENCES
  },
  {
    label: t('settings.modal.security'),
    icon: 'i-heroicons-shield-check',
    key: NavigationKeys.SECURITY
  },
  {
    label: t('settings.modal.changelog'),
    icon: 'i-heroicons-book-open',
    key: NavigationKeys.CHANGELOG
  }
];

const isMnemonicVisible = ref(false);
const hiddenSeedPhrase = generateFakeSeedPhraseForDisplay();

function generateFakeSeedPhraseForDisplay () {
  let fakeSeedPhrase = '';

  for (let i = 0; i < 24; i++) {
    const wordLength = faker.helpers.rangeToNumber({ min: 5, max: 9 });
    fakeSeedPhrase += `${i === 0 ? '' : ' '}${faker.lorem.word(wordLength)}`;
  }

  return fakeSeedPhrase;
}

async function onCurrencyChange () {
  try {
    const newCurrencyCode = preferences.currency.enumCodeReference;
    await Promise.all([
      hydraStore.changeCurrency(newCurrencyCode),
      currencyStore.fetchCurrencies(newCurrencyCode)
    ]);

    await walletStore.fetchWalletAssets();
  } catch (err) {
    toast.add({
      color: 'red',
      title: t('currencies.errors.fetch'),
      icon: 'i-heroicons-exclamation-triangle',
      closeButton: {
        icon: ''
      }
    });
  }
}

const currentTabKey = ref(NavigationKeys.PREFERENCES);
const selectedTabIndex = computed({
  get () {
    const index = navigation.findIndex(item => item.key === currentTabKey.value);
    if (index === -1) {
      return 0;
    }

    return index;
  },
  set (value:number) {
    currentTabKey.value = navigation[value].key;
  }
});

const modalHeadings = computed(() => {
  if (currentTabKey.value === NavigationKeys.PREFERENCES) {
    return {
      title: t('settings.modal.preferences'),
      subtitle: t('settings.modal.preferences.subtitle')
    };
  } else if (currentTabKey.value === NavigationKeys.SECURITY) {
    return {
      title: t('settings.modal.security'),
      subtitle: t('settings.modal.security.subtitle')
    };
  } else if (currentTabKey.value === NavigationKeys.CHANGELOG) {
    return {
      title: t('settings.modal.changelog'),
      subtitle: t('settings.modal.changelog.subtitle')
    };
  }
});
</script>

<template>
  <UModal
    :ui="{ width: 'md:min-w-[48rem] w-full' }"
  >
    <UCard
      :ui="{
        body: {
          base: 'h-full',
        },
      }"
    >
      <template #header>
        <ModalHeader v-bind="modalHeadings" closable @close="close" />
      </template>
      <section class="flex w-full flex-col gap-y-4 md:flex-row md:items-start">
        <USelectMenu v-model="currentTabKey" class="md:hidden" size="lg" :options="navigation" value-attribute="key">
          <template #label>
            <UIcon
              :name="navigation[selectedTabIndex].icon"
              class="u-text-gray-600 size-4"
            />
            {{ navigation[selectedTabIndex].label }}
          </template>
        </USelectMenu>

        <UTabs
          v-model="selectedTabIndex"
          :items="navigation"
          orientation="vertical"
          class="hidden md:block"
          :ui="{
            wrapper: 'w-1/3 flex-none',
            list: {
              base: 'relative top-0 h-full text-sm',
              background: 'bg-transparent dark:bg-transparent',
              padding: 'p-0 md:pr-4',
              shadow:'shadow-none',
              marker: {
                base: 'w-full h-full',
                background: 'bg-transparent',
                shadow: 'shadow-none',
              },
              tab: {
                base: 'flex inline-flex',
                padding: 'py-2 px-0',
                height: 'h-10',
                active: '',
                size: 'text-sm',
              },
            },
          }"
        >
          <template #default="{ item,selected }">
            <div
              class="flex w-full items-center justify-start gap-2"
              :class="{
                'u-text-gray-900':selected,
                'u-text-gray-500':!selected,
              }"
            >
              <UIcon
                :name="item.icon"
                class="size-6"
              />

              <span class="truncate">{{ item.label }}</span>
            </div>
          </template>
        </UTabs>

        <DescriptionList
          v-if="currentTabKey === NavigationKeys.PREFERENCES"
          class="u-border-gray-200 w-full justify-items-center border-t-2 pt-3 md:border-l-2 md:border-t-0 md:pl-3 md:pt-0"
        >
          <ListItem full-width>
            <template #label>
              {{ $t('settings.modal.preferences.theme') }}
            </template>
            <template #value>
              <USelectMenu
                v-model="theme"
                :options="themeOptions"
                option-attribute="label"
                by="value"
              >
                <template #label>
                  <UIcon
                    :name="theme.icon"
                    class="u-text-gray-600 size-4"
                  />
                  {{ theme.label }}
                </template>
              </USelectMenu>
            </template>
          </ListItem>
          <ListItem full-width>
            <template #label>
              {{ $t('settings.modal.preferences.primary') }}
            </template>
            <template #value>
              <USelectMenu
                v-model="primaryColor"
                :options="primaryColors"
                by="value"
              >
                <template #label>
                  <span
                    class="inline-block size-3 rounded-full"
                    :style="{
                      backgroundColor: '#' + primaryColor.chip,
                    }"
                  />
                  {{ primaryColor.label }}
                </template>
              </USelectMenu>
            </template>
          </ListItem>
          <ListItem full-width>
            <template #label>
              {{ t('settings.modal.preferences.background') }}
            </template>
            <template #value>
              <USelectMenu
                v-model="grayTone"
                :options="grayTones"
                by="value"
              >
                <template #label>
                  <span
                    class="inline-block size-3 rounded-full"
                    :style="{ backgroundColor: '#' + grayTone.chip }"
                  />
                  {{ grayTone.label }}
                </template>
              </USelectMenu>
            </template>
          </ListItem>
          <ListItem full-width>
            <template #label>
              {{ t('settings.modal.preferences.currency') }}
            </template>
            <template #value>
              <USelectMenu
                v-model="preferences.currency"
                :options="currencies"
                option-attribute="code"
                :searchable="width >= Breakpoints.sm"
                by="code"
                @change="onCurrencyChange"
              >
                <template #label>
                  <LabelWithAuxText
                    :label="preferences.currency.symbol + preferences.currency.code"
                    :auxiliary-text="preferences.currency.name"
                  />
                </template>
                <template #option="{ option }">
                  <LabelWithAuxText
                    :label="option.symbol + option.code"
                    :auxiliary-text="option.name"
                  />
                </template>
              </USelectMenu>
            </template>
          </ListItem>
          <ListItem full-width>
            <template #label>
              {{ t('settings.modal.preferences.timezone') }}
            </template>
            <template #value>
              <USelectMenu
                v-model="preferences.timezone"
                :options="timezones"
                :searchable="width >= Breakpoints.sm"
                option-attribute="city"
                by="city"
              >
                <template #label>
                  <LabelWithAuxText
                    :label="preferences.timezone.city"
                    :auxiliary-text="preferences.timezone.time"
                  />
                </template>
                <template #option="{ option }">
                  <LabelWithAuxText
                    :label="option.city"
                    :auxiliary-text="option.time"
                  />
                </template>
              </USelectMenu>
            </template>
          </ListItem>
          <ListItem full-width>
            <template #label>
              {{ $t('settings.modal.preferences.timeformat') }}
            </template>
            <template #value>
              <div
                class="flex h-full items-center justify-start gap-2 md:justify-end"
              >
                <span class="text-sm font-medium">{{
                  $t('settings.modal.preferences.timeformat.full')
                }}</span>
                <UToggle
                  v-model="preferences.is12Hour"
                  color="primary"
                  size="md"
                />
                <span class="text-sm font-medium">{{
                  $t('settings.modal.preferences.timeformat.half')
                }}</span>
              </div>
            </template>
          </ListItem>
          <ListItem full-width>
            <template #value>
              <div class="space-y-6 text-right">
                <UButton
                  color="gray"
                  @click="resetToDefaults"
                >
                  {{ $t('settings.modal.reset') }}
                </UButton>
              </div>
            </template>
          </ListItem>
        </DescriptionList>
        <DescriptionList
          v-if="currentTabKey === NavigationKeys.SECURITY"
          class="u-border-gray-200 min-h-[30rem] w-full justify-items-center border-t-2 pt-3 md:border-l-2 md:border-t-0 md:pl-3 md:pt-0"
        >
          <ListItem full-width>
            <template #label>
              {{ $t('settings.modal.security.remember') }}
              <UTooltip :text="$t('settings.modal.security.remember.tooltip')">
                <UIcon
                  name="i-heroicons-information-circle"
                  class="u-text-gray-600 m-1 size-4"
                />
              </UTooltip>
            </template>
            <template #value>
              <div
                class="flex h-full items-center justify-start gap-2 md:justify-end"
              >
                <span class="text-sm font-medium">{{
                  $t('settings.modal.security.remember.off')
                }}</span>
                <UToggle
                  v-model="preferences.persistMnemonic"
                  color="primary"
                  size="md"
                />
                <span class="text-sm font-medium">{{
                  $t('settings.modal.security.remember.on')
                }}</span>
              </div>
            </template>
          </ListItem>

          <ListItem full-width>
            <template #value>
              <div class="space-y-4">
                <div>
                  <p class="u-text-gray-900 mb-2 text-sm font-medium">
                    {{ $t('settings.modal.security.mnemonic') }}
                  </p>
                  <div class="u-border-gray-200 u-text-gray-600  rounded border border-dashed p-2">
                    <span v-if="isMnemonicVisible" class="text-sm">
                      {{ mnemonic?.toString() }}
                    </span>
                    <span v-else class="text-sm blur-[2px]"> {{ hiddenSeedPhrase }}</span>
                  </div>
                </div>

                <UButton
                  block
                  class="relative  uppercase"
                  color="red"
                  @click="isMnemonicVisible = !isMnemonicVisible"
                >
                  {{ isMnemonicVisible ? $t('settings.modal.security.mnemonic.cta.hide') : $t('settings.modal.security.mnemonic.cta.reveal') }}
                </UButton>
              </div>
            </template>
          </ListItem>
        </DescriptionList>
        <div
          v-if="currentTabKey === NavigationKeys.CHANGELOG"
          class="u-border- gray-200 min-h-[30rem] w-full justify-items-center border-t-2 pt-3 md:border-l-2 md:border-t-0 md:pl-3 md:pt-0"
        >
          <div class="prose prose-sm prose-primary dark:prose-invert max-w-none">
            <ContentDoc path="changelog/latest" />
          </div>
        </div>
      </section>

      <template
        v-if="width < Breakpoints.sm"
        #footer
      >
        <UButton
          block
          @click="close"
        >
          {{ t('settings.modal.preferences.button.close') }}
        </UButton>
      </template>
    </UCard>
  </UModal>
</template>

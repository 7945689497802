import { useLocalStorage } from '@vueuse/core';
import { useStringEncryption } from './useStringEncryption';
import { Mnemonic } from 'hydra-node';
import { LocalStorageIds } from '~/enums/localStorage';

export function useMnemonicManager () {
  const { encryptData, decryptData } = useStringEncryption();
  const localMnemonic = useLocalStorage(LocalStorageIds.mnemonic, '');

  const resetLocalStorageMnemonic = () => {
    localMnemonic.value = null;
  };

  const setLocalStorageMnemonic = (mnemonicValue: string) => {
    useLocalStorage(LocalStorageIds.mnemonic, '').value = encryptData(mnemonicValue);
  };

  const readLocalStorageMnemonic = () => {
    return decryptData(useLocalStorage(LocalStorageIds.mnemonic, '').value);
  };

  const generateMnemonic = (is24WordsMnemonic: boolean) => {
    return Mnemonic.generateNew(
      is24WordsMnemonic ? 24 : 12
    ).toString();
  };

  return {
    resetLocalStorageMnemonic,
    setLocalStorageMnemonic,
    generateMnemonic,
    readLocalStorageMnemonic,
    localMnemonic
  };
}
